// @ts-strict-ignore
import Router from 'next/router';
import DefaultLayout from 'src/components/layouts/default';
import CommunityLayout from 'src/components/layouts/community';
import ErrorPage from 'src/components/error';
import { PageContext, Community } from 'src/constants/types';

export class RequestError extends Error {
  response: {
    status: number | null;
    currentCommunity?: Community | null;
  };
  constructor(message: string, status: number | null, currentCommunity?: Community | null, ...params) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(...params);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, RequestError);
    }

    this.name = String(status);
    this.message = message;
    this.response = { status, currentCommunity };
  }
}

interface PageContextOrError extends PageContext {
  error: boolean;
  status: number;
  currentCommunity: Community,
}

const withErrorHandling = (Child) => {
  const WrappedComponent = (props: PageContextOrError) => {
    if (props.error) {
      if (props.currentCommunity) {
        return (
          <CommunityLayout fullWidth={true}>
            <ErrorPage status={props.status} />
          </CommunityLayout>
        );
      } else {
        return <DefaultLayout><ErrorPage status={props.status} /></DefaultLayout>;
      }
    }
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Child {...props} />;
  };

  WrappedComponent.getInitialProps = async (props: PageContext) => {
    try {
      return await Child.getInitialProps(props);
    } catch (ex) {
      const status = ex.response?.status || 500;
      const currentCommunity = ex.response?.currentCommunity;
      const { currentUser } = props.store.getState().context;
      const isLoginRequired = (status: number) => !currentUser && (status === 310 || status === 401);

      if (isLoginRequired(status)) {
        if (props.res) {
          props.res.writeHead(302, { Location: `/login${props.asPath}` }).end();
        } else {
          Router.push(`/login${props.asPath}`);
        }
      } else {
        return {
          error: true,
          currentCommunity,
          status,
          namespacesRequired: ['common'],
        };
      }
    }
  };

  return WrappedComponent;
};

export default withErrorHandling;
