// @ts-strict-ignore
import { Formik, Form } from 'formik';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'src/store/store';
import i18n from 'src/utils/translate';
import ValidatedTextInput from 'src/components/inputs/validatedTextInput';
import Button from 'src/components/buttons/button';
import { trackFootfallEvent } from 'src/utils/footfall';
import { Event } from 'src/constants/footfall';
import { login, isEmailAvailable } from 'src/modules/shared/auth/actions';
import { showOnboarding } from 'src/modules/shared/modal/actions';
import { required, minLength, maxLength, email as emailField, applyValidation } from 'src/utils/validators';
import { emailMaxLength, emailErrors, passwordMinLength, passwordMaxLength, passwordErrors } from 'src/constants/validationErrors';
import Spacer from 'src/components/spacer';
import { textSmall } from 'styles-js/mixins/typography';

type Props = {
  isCommunitySignup?: boolean;
  onSignupSuccess?: () => void;
  redirectPage?: () => void;
};

const schema = {
  email: [
    required(emailErrors.required),
    emailField(emailErrors.invalid),
    maxLength(emailMaxLength, emailErrors.max),
  ],
  password: [
    required(passwordErrors.required),
    minLength(passwordMinLength, passwordErrors.min),
    maxLength(passwordMaxLength, passwordErrors.max),
  ],
};

export default function SignupForm({
  isCommunitySignup,
  onSignupSuccess,
  redirectPage,
}: Props): JSX.Element {
  const dispatch = useDispatch();

  const { defaultEmail } = useSelector(({ modal }) => modal.modalProps);

  const onSubmit = async ({ email, password }: { email: string, password: string }) => {

    const isAvailable = await isEmailAvailable(email);
    if (!isAvailable) {
      // maybe they think they're on the login form, not signup.
      // so let's just try signing them in with the details provided
      await dispatch(login({
        password,
        username: email,
        onLoginSuccess: onSignupSuccess,
      }));
      trackFootfallEvent(Event.CLICKED, { clickType: 'join-email-taken' });
    } else {
      trackFootfallEvent(Event.CLICKED, { clickType: 'join-email' });
      dispatch(showOnboarding({
        authData: { email, password },
        onSignupSuccess,
        isCommunitySignup,
        redirectPage,
      }));
    }
  };

  return (
    <Formik
      initialValues={{ email: defaultEmail || '', password: '' }}
      validate={(values) => applyValidation(schema, values)}
      onSubmit={onSubmit}
    >
      {({ errors, touched, values, handleChange, handleBlur }) => (
        <StyledForm data-testid="signup-form">
          <ValidatedTextInput
            dataTestId="email-username-input"
            id="email"
            label={i18n.t('Email:')}
            labelExtra={i18n.t('(never publicly displayed)')}
            meta={{ touched: touched['email'] as boolean, error: errors['email'] as string }}
            placeholder={i18n.t('example@email.com')}
            type="email"
            value={values['email']}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <ValidatedTextInput
            dataTestId="password-input"
            id="password"
            label={i18n.t('Password:')}
            meta={{ touched: touched['password'], error: errors['password'] }}
            placeholder={i18n.t('create a password')}
            type="password"
            value={values['password']}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <Spacer />
          <Button fullWidth={true} isSubmit={true} text={i18n.t('Join with email')} />
        </StyledForm>
      )}
    </Formik>
  );
}

const StyledForm = styled(Form)`
  text-align: left;
  width: 100%;

  label {
    ${textSmall}
    margin-top: 10px;
    margin-bottom: 0;
    font-weight: ${({ theme }) => theme.fontWeightBold};
  }
`;
